.progress-active {
    /* position: absolute; */
    z-index: 1;
    /* background-color: antiquewhite; */
    /* opacity: 100%; */
    align-items: center;
    text-align: center;
}

/* .upload-file{
    transform: translate(0%, -180%);
    min-height: 500px;
    min-width: 500px;
    background-color: antiquewhite;
    opacity: 30%;
} */
.upload-spinner {
    margin: auto;
    height: 2rem;
    width: 2rem;
    opacity: 70%;

}

.upload-file button {
    margin: 5px;
}

table button {
    margin-right: 5px;
}

table {
    counter-reset: rowNumber;
}

tbody tr>td:first-child {
    counter-increment: rowNumber;
}

tbody tr td:first-child::before {
    content: counter(rowNumber);
    width: 1px;
}